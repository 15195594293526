body {
    font-family: "Poppins", sans-serif;
    background: #fff;
    overflow-x: hidden;
}
.navbar-collapse.collapse.show {
    display: block !important;
}
.nav-inner {
    margin-left: auto;
    margin-right: 0 !important;
}

/********01-08-2024***********/
a, h1, h2, h3, h4, h5, h6, div, section, span, ul, li, p {
    font-family: "Poppins", sans-serif;
}
.step-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.step-count.blue {
    color: #45a3ff30;
}
.step-info-title.blue {
    color: #45A3FF;
}
.step-object {
    width: 30%;
    padding: 0px 30px;
}
.step-info p {
    color: #313131;
    font-size: 14px;
    line-height: 24px;
    margin: 0px;
}
.step-info-title {
    font-weight: 500;
    color: #09D7A6;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 5px;
}
.step-object img {
    width: 100%;
}
.step-count.green {
    color: #35d1643d;
}
.step-info-title.green {
    color: #35D164;
}
.step-info-title.yellow {
    color: #FFB238;
}
.step-count.yellow {
    color: #ffb23842;
}
.step-count {
    font-size: 60px;
    font-weight: 600;
    color: #09d7a64a;
    width: 35%;
    text-align: right;
}
.step-info {
    text-align: left;
    width: 35%;
}
section.pricing-sec.steps-section.bg-none {
    background-image: none;
}

.privacy-main-outer{}
.privacy-main-outer h3{text-align: left;}
.privacy-main-outer p{text-align: left;}
/******************/
p.company-text {
    margin: 0px;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
}
p.company-text a {
    color: #fff;
    font-family: "Poppins", sans-serif;
    text-decoration: underline !important;
}
.login-input.bank-account input.custom-input {
    padding-left: 20px;
}
ul.card-ul {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 44px;
    right: 15px;
}
ul.card-ul li {
    display: inline-block;
    margin-left: 5px;
}
select.custom-input {
    padding-left: 15px;
    -webkit-appearance: none;
    background-image: url(../images/down-arrow.png);
    background-repeat: no-repeat;
    background-position: right 15px top 24px;
}
ul.card-ul {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 44px;
    right: 20px;
}
ul.card-ul.cvc-ul {
    top: 16px;
}
input.custom-input.affilate-link-input {
    padding-left: 15px;
    padding-right: 60px;
}
.login-inner.affilate-link-div {
    padding-top: 100px !important;
}
a.copy-link {
    position: absolute;
    right: 6px;
    width: 35px;
    height: 44px;
    background-color: #15c39a;
    text-align: center;
    line-height: 44px;
    color: #fff;
    border-radius: 5px;
    top: 6px;
}
.profile-inner.edit-profile-div {
    align-items: flex-start;
}
.profile-inner.edit-profile-div.change-password {
    margin-top: 20px;
    padding-right: 20%;
}
img.colored {
    display: none;
}
ul.profile-ul li.active a .colored {
    display: inline-block;
}
ul.profile-ul li.active a .white-img {
    display: none;
}
.profile-inner.edit-profile-div button.login-btn1 {
    margin: 0px 0px;
}
a.camera-img {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #15C39A;
    text-align: center;
    line-height: 40px;
    border-radius: 100%;
    right: 0;
    bottom: 10px;
}
.profile-ul {
    padding: 0;
    margin: 0;
}
ul.profile-ul {
    padding: 0;
    margin: 0;
    /* background-color: #09D7A6; */
    border-radius: 10px;
    margin-right: -50px;
    z-index: 99;
    position: relative;
    margin-top: 10%;
}
ul.profile-ul li a {
    padding: 20px;
    display: inline-block;
    background-color: #09D7A6;
    width: 83px;
}
.edit-profile a {
    color: #333333;
    font-size: 14px;
    line-height: 24px;
    font-family: "Poppins", sans-serif;
}
.profile-inputs {
    width: 100%;
}
.profile-input {
    font-size: 16px;
    color: #333333;
    line-height: 26px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 15px;
}
label.profile-label {
    font-size: 14px;
    color: #7E7E7E;
    line-height: 24px;
    margin-bottom: 5px;
    font-family: "Poppins", sans-serif;
}
.profile-img {
    width: 168px;
    height: 168px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 60px;
    position: relative;
}
.profile-inner {
    display: flex;
    margin: 60px 0px;
}
.profile-inner {
    display: flex;
    margin-top: 60px;
    align-items: center;
}
.edit-profile a img {
    margin-right: 10px;
}
.edit-profile {
    text-align: right;
    margin-bottom: 10px;
}
ul.profile-ul li:last-child a {
    border-radius: 0px 0px 10px 10px;
}
ul.profile-ul li:nth-child(1) a {
    border-radius: 10px 10px 0px 0px;
}
ul.profile-ul li.active a {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 0px #09d7a624;
    padding: 25px;
    width: 103px;
}
.profile-ul li {
    list-style: none;
}
.profile-div .login-inner {
    width: 100%;
    padding: 45px 40px 45px 150px;
}
.profile-div {
    width: 80%;
    display: flex;
    /* align-items: center; */
    margin: 50px auto;
}
.amazon-div img {
    width: 89px;
    height: 89px;
    object-fit: contain;
    border-radius: 100%;
}
button.login-btn1.see-ads {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    margin: 0px 0px 15px 0px;
    padding: 6px;
    border-radius: 3px;
}
.bookmark-title i.fa-solid.fa-star {
    color: #EAB308;
}
.amazon-div {
    padding: 44px 20px;
}
p.banner-p {
    font-size: 16px;
    color: #fff;
    line-height: 26px;
    font-family: "Poppins", sans-serif;
}
.contact-us-inner {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashbaord-title {
    font-size: 20px;
    color: #333333;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}
button#logout-dropdown a, a.dropdown-item {
    font-family: "Poppins", sans-serif;
}
button#logout-dropdown img {
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    border-radius: 100%;
    object-fit: cover;
}
span.bi.bi-chevron-down.ml-1.small {
    margin-right: 10px;
}
span.user-name {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    margin: 0px 6px;
}
button#logout-dropdown {
    background-color: #0AAF88;
    border-radius: 30px;
    padding: 1px;
    height: 35px;
    display: flex;
    align-items: center;
    color: #fff;
    font-family: "Poppins", sans-serif;
}
main.container-fluid.main-container {
    padding: 0pc !important;
}
.bookmark-title span, .d-media-active, .sponser-title {
    color: #333333;
    font-size: 12px;
    line-height: 22px;
    font-family: "Poppins", sans-serif;
    text-align: left;
}
ul.sponser-ul li {
    list-style: none;
    color: #333333;
    font-size: 12px;
    line-height: 24px;
    font-family: "Poppins", sans-serif;
    text-align: left;
}
img.s-img {
    width: 100%;
    height: 137px;
    object-fit: cover;
    border-radius: 10px;
}
.extension-bar.dashbaord-extension {
    position: inherit;
    margin: 0px 0px;
    border-radius: 10px;
    width: 82%;
    margin: 0 auto;
    box-shadow: 0px 5px 10px 0px #0aaf8826;
    align-items: center;
}
label.amount-spent.m-0 {
    margin: 0pc !important;
}
.extension-bar.dashbaord-extension .extension-left:nth-child(1) {
    width: 55%;
}
.extension-bar.dashbaord-extension .extension-left:nth-child(2) {
    width: 30%;
}
.extension-bar.dashbaord-extension .extension-left:nth-child(3) {
    width: 15%;
}
a.sort-ads-a.filter-btn {
    width: 100%;
    margin: 0pc !important;
}
a.sort-ads-a.filter-btn img {
    margin-right: 7px;
}
.extension-bar.dashbaord-extension .extension-left {
    border: none !important;
    padding: 0px 15px !important;
}
.sponsered-div img {
    margin-top: 5px;
}
.sponser-right p {
    color: #949494;
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    margin: 0px;
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #15C39A;
}

.d-media-left p {
    color: #333333;
    font-size: 12px;
    line-height: 18px;
    font-family: "Poppins", sans-serif;
    text-align: left;
    margin-bottom: 5px;
}
ul.sponser-ul {
    padding: 0px;
    text-align: left;
}
.check-spend{
    display: block;
    font-size: 10px;
    color: #fff;
    background-color: #09D7A6;
    margin-bottom: 3px;
    border-radius: 2px;
    padding: 3px 2px;
    font-family: "Poppins", sans-serif;
    border: 1px solid #09D7A6;
    width: 70px;
}
.d-media-right span {
    display: block;
    font-size: 10px;
    color: #fff;
    background-color: #09D7A6;
    margin-bottom: 3px;
    border-radius: 2px;
    padding: 3px 2px;
    font-family: "Poppins", sans-serif;
    border: 1px solid #09D7A6;
    width: 70px;
}
.sponser-right {
    margin-left: 5px;
}
.amazon-div button.login-btn1 {
    font-size: 14px;
    line-height: 24px;
    border-radius: 3px;
    padding: 6px;
}
p.desc-text1 {
    text-align: left;
    color: #7E7E7E;
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins", sans-serif;
}
img.follow-img {
    width: 100%;
    height: 225px;
    border-radius: 10px;
    margin: 10px 0px;
    object-fit: cover;
}
.time-span-div.d-media-right {
    display: flex;
    gap: 7px;
}
.amazon-title {
    font-size: 24px;
    color: #333333;
    line-height: 34px;
    margin: 10px 0px 15px 0px;
    font-family: "Poppins", sans-serif;
}
.amazon-div p {
    font-size: 14px;
    color: #333333;
    font-family: "Poppins", sans-serif;
    margin: 0px;
}
.sponsered-div {
    display: flex;
    align-items: flex-start;
    margin: 5px 0px;
    text-align: left;
}
a.download-media-addbtn {
    display: block;
    color: #15C39A;
    font-size: 12px;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px #f2f2f2;
    font-family: "Poppins", sans-serif;
    margin: 5px 0px;
    font-weight: 600;
}
span.views-time {
    color: #09D7A6;
    background-color: #fff;
    border: 1px solid #09D7A6;
}
span.views-price {
    background-color: #333333;
    border-color: #333333;
}
.d-media-left {
    width: calc(100% - 70px);
    padding-right: 7px;
}
.d-media-left p i {
    width: 16px;
    height: 16px;
    border: 1px solid #333333;
    text-align: center;
    line-height: 16px;
    border-radius: 100%;
}
.d-media-active {
    margin-bottom: 5px;
}
.d-media-active i {
    color: #16c60c;
    font-size: 14px;
    margin-right: 5px;
}
.bookmark-title a {
    color: #D8D8D8;
}
.bookmark-title a.bookmark-saved {
    color: #09D7A6;
}
.d-media {
    display: flex;
    justify-content: space-between;
}
.download-media {
    padding: 13px 17px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
}
.bookmark-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}
.col-md-9.col-lg-10.ml-md-auto.px-0.custom-nine-col {
    max-width: calc(100% - 72px) !important;
    width: calc(100% - 72px) !important;
    flex: 0 0 95%;
    margin-top: 61px;
    padding: 25px !important;
    background-color: #f5f5f5;
    min-height: calc(100vh - 61px);
}
.contact-left {
    width: 40%;
    background: linear-gradient(155deg, rgba(255, 255, 255, 1) 0%, rgba(21, 195, 154, 1) 0%, rgba(9, 215, 166, 1) 100%);
    border-radius: 20px 0px 0px 20px;
    height: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
}
.sidebar-left {
    position: relative;
    height: calc(100vh - 61px);
}
.dashbaord-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.sidebar-left a.active img {
    filter: brightness(0) invert(1);
}
.sidebar-left a.active {
    background-color: #09D7A6;
    box-shadow: -5px 11px 20px 0px #09d7a624;
}
.sidebar-left a {
    padding: 18px;
    width: 90%;
    border-radius: 0px 15px 15px 0px;
    position: relative;
    margin: 20px 0px 0px 0px;
}
.sidebar-left a::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 70%;
    background-color: #fff;
    left: 0;
    top: 10px;
    border-radius: 0px 30px 30px 0px;
}
.sidebar {
    top: 61px;
    left: 0;
    /* z-index: 100; */
    overflow-y: auto;
    width: 72px;
    border-right: 1px solid #C7E7DF;
}
a.logout {
    padding: 15px;
    position: absolute;
    bottom: 0;
}
nav.w-100.d-flex.px-4.py-2.mb-4.shadow-sm {
    z-index: 999;
    position: relative;
    position: fixed;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 0;
}
  
  .overlay{
    background-color: rgb(0 0 0 / 45%);
    z-index: 99;
  }
  
.send-message {
    font-size: 22px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 32px;
    font-family: "Poppins", sans-serif;
}
.contact-right.login-inner {
    padding: 55px;
    width: calc(100% - 40%);
}
img.contact-bg {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
}
.support-btn {
    display: inline-block;
    border: 1px solid #09D7A6;
    padding: 10px 20px;
    font-size: 18px;
    color: #333333;
    background-color: #09d7a60d;
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    margin-top: 10px;
    margin-bottom: 40px;
}
.support-btn img {
    margin-right: 10px;
}
.support-btn:hover {
    background-color: #000;
    border-color: #000;
    color: #fff;
}
.learn-left {
    padding: 10px !important;
}
button.login-btn1.login-spyderads {
    margin-left: 0;
    margin-bottom: 0px;
    width: auto;
    padding: 10px 20px;
    min-width: 241px;
}
span.loginto-span {
    width: calc(100% - 80px);
}
.feature-upper.count-feature-upper {
    align-items: flex-start !important;
}
span.count-number {
    width: 53px;
    height: 53px;
    text-align: center;
    color: #0cd1a3;
    box-shadow: 0px 0px 8px 0px #0cd1a31f;
    border-radius: 100%;
    line-height: 53px !important;
    font-size: 30px !important;
    margin-right: 15px;
}
.lifetime-plan {
    box-shadow: 0px 0px 15px 0px #15c39a36;
    border-radius: 20px;
    padding: 45px;
    position: relative;
    background-image: url(../images/cmsn-arc.png);
    background-repeat: no-repeat;
}
.custom-modal .modal-body p {
    color: #7E7E7E;
    font-size: 14px;
    margin-bottom: 30px;
    font-family: "Poppins", sans-serif;
}
.custom-modal .modal-body h3 {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}
a.login-btn1.skip-btn {
    background-color: transparent;
    color: #BCBCBC !important;
    font-size: 12px;
    margin-top: 5px;
}
.ok-skip-div a {
    margin: 0pc auto;
}
.ok-skip-div a.login-btn1 {
    color: #fff;
}
.link {
    font-size: 14px;
    color: #333333;
    background-color: #09d7a61a;
    border-radius: 5px;
    padding: 15px;
    margin: 20px 0px;
    font-family: "Poppins", sans-serif;
    text-align: left;
}
.custom-modal .modal-dialog {
    max-width: 700px;
}
.affilate-link {
    font-size: 34px;
    color: #333333;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 5px;
}
.custom-modal .modal-body {
    padding: 40px;
}
.custom-modal button.close {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: none;
    box-shadow: 0px 0px 15px 0px #3b3b3b45;
    font-size: 40px;
    line-height: 40px;
    background-color: #fff;
    color: #333333;
    right: -15px;
    position: absolute;
    top: -15px;
}
.custom-modal .modal-content {
    border-radius: 20px;
    border: none;
    box-shadow: 0px 0px 10px 0px #00000009;
}
img.commission {
    position: absolute;
    right: -50px;
    top: -50px;
    width: 120px;
}
.lifetime-plan::after {
    content: 'Lifetime';
    position: absolute;
    left: 0px;
    top: 35px;
    color: #fff;
    font-size: 25px;
    transform: rotate(-55deg);
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}
.lifetime-plan::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    padding: 0;
    margin: 0;
    border-style: solid;
    border-width: 0 0 180px 120px;
    border-color: transparent transparent transparent #15c39a;
    left: 0;
    border-radius: 20px 0px 0px 0px;
}
section.our-top-features .match-div {
    border: 1px solid #15c39a;
}
section.our-top-features .match-div.active {
    border: 1px solid transparent;
}
section.banner-section.pricing-banner {
    background-image: url(../images/pricing-bg.png);
    min-height: 320px;
    background-position: bottom -50px center;
    padding-top: 60px;
}
.main-nav {
    background: linear-gradient(155deg, rgba(255,255,255,1) 0%, rgba(21,195,154,1) 0%, rgba(9,215,166,1) 100%);
}
.custom-navbar {
    border-bottom: 1px solid #ffffff29;
}
span.circle1.small-circle {
    width: 101px;
    height: 101px;
}
.login-outer {
    min-height: 100vh;
}
span.circle {
    position: absolute;
    width: 248px;
    height: 248px;
    filter: blur(44px);
    background-color: #7dfaea1f;
    border-radius: 100%;
    top: -20px;
    right: 20px;
}
.about-left .main-title {
    color: #15C39A;
    font-size: 35px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 15px !important;
}
.about-left {
    text-align: left;
    width: 42%;
}
.about-us-divv {
    display: flex;
    justify-content: space-between;
}
.worldwide-title {
    font-size: 24px;
    color: #333333;
    margin: 15px 0px 8px 0px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}
.pricing-sec.steps-section {
    background-image: url(../images/steps-bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
}
.step1.step2 {
    box-shadow: 0px 0px 45px 0px #12c89d1f;
}
.step-title {
    font-size: 14px;
    color: #7E7E7E;
    margin-top: -20px;
    font-family: "Poppins", sans-serif;
}
.step1 {
    background-color: #fff;
    padding: 41px;
    border-radius: 20px;
}
.step1 img {
    width: 100%;
}
.worldwide.center {
    background-color: #FFF6F0;
}
.worldwide.right {
    background-color: #EEFBF8;
}
.worldwide img {
    width: 78px;
    height: 98px;
    object-fit: contain;
}
.worldwide p {
    color: #7E7E7E;
    font-size: 14px;
    margin: 0px;
    font-family: "Poppins", sans-serif;
}
.worldwide {
    border-radius: 10px;
    background-color: #F0FAFF;
    padding: 35px;
}
.about-left p {
    color: #7E7E7E;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    line-height: 26px;
}
/*******14-05-2024 extension-bar********/
.search-input input {
    color: #15C39A;
    border: none;
    background-color: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 15px;
}
a.sort-ads-a.last {
    margin: 0px !important;
}
label.amount-spent {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;
}
.range-slider-div span {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    color: #15c39a;
    margin-left: 10px;
}
.range-slider-div {
    display: flex;
    align-items: center;
    width: calc(100% - 200px);
    margin-left: 15px;
}
.range-slider-div input[type='range'] {
    -webkit-appearance: none;
    background-color: #15c39a45;
    height: 6px;
    overflow: visible;
    width: 300px;
    position: relative;
    border-radius: 30px;
}
  
  .range-slider-div input[type='range']::-webkit-slider-thumb {  
    -webkit-appearance: none;  
    background-color: #15C39A;
    box-shadow: 1px 1px 8px #15c39a52;  
    width: 12px;  
    height: 12px;
    border-radius: 50%;
    position: relative;
    z-index: 100;
  } 
  
  .range-slider-div input[type='range']::-webkit-slider-thumb:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 40%;
    width: 10%;
    height: 200px;
    display: block;
    background: red;
    z-index: -10;
    box-shadow: 0 0 10px darkred;
    opacity: .1;
  }
  
  .range-slider-div input[type='range']:hover::-webkit-slider-thumb:before {
    opacity: 1;
  }

  a.sort-ads-a {
    border: 1px solid #15C39A;
    border-radius: 5px;
    color: #333333;
    font-size: 12px;
    padding: 6px 8px;
    display: inline-block;
    font-family: "Poppins", sans-serif;
    width: 48%;
    text-align: center;
    margin-right: 10px;
}
.extension-left.center {
    padding-left: 30px;
}
.extension-left.right {
    border-right: none;
    padding-left: 35px;
    padding-right: 0px;
}
.extension-left.right a.sort-ads-a {
    background-color: #15C39A;
    color: #fff;
}
.search-input input:focus {
    border: none;
    outline: none;
}
.extension-left {
    padding: 0px 30px 0px 10px;
    border-right: 1px solid #09d7a657;
    width: 33.3%;
    text-align: left;
}
.extension-bar {
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px #7b7b7b59;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 15px;
    padding: 12px;
    display: flex;
    z-index: 9;
}
.extension-left label {
    font-weight: bold;
    color: #333333;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    margin-bottom: 5px;
}
.search-input {
    background-color: #09d7a712;
    padding: 5px;
    /* position: relative; */
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.search-input input::placeholder {
    color: #15C39A;
}
.search-input i {
    background-color: #15C39A;
    width: 27px;
    height: 27px;
    border-radius: 100%;
    color: #fff;
    line-height: 27px;
    text-align: center;
}
/*******14-05-2024 extension-bar********/
.login-input.otp-inputs input {
    width: 100% !important;
    background-color: #09d7a612;
    font-size: 14px;
    color: #9B9B9B;
    border: 1px solid transparent;
    padding: 20px;
    border-radius: 10px;
    margin: 0px 5px;
    font-family: "Poppins", sans-serif;
}
.login-input.otp-inputs input:nth-child(1) {
    margin-left: 0px;
}
.login-input.otp-inputs input:nth-last-child() {
    margin-right: 0px;
}
.login-input.otp-inputs input:focus {
    outline: none;
    border-color: #09D7A6;
}
.login-outer {
    min-height: 100vh;
    position: relative;
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../images/login-bg.svg);
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}
a.back-home i {
    font-size: 20px;
    margin-right: 5px;
}
a.back-home {
    background-color: #09D7A7;
    color: #fff;
    display: inline-flex;
    padding: 7px 7px;
    border-radius: 30px;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    transition: 0.3s;
    align-items: center;
    font-weight: 500;
    margin-bottom: 30px;
}
/* The container */
.keep-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    -webkit-user-select: none;
    user-select: none;
    color: #7E7E7E;
}
  
  /* Hide the browser's default checkbox */
  .keep-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .keep-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #BDB6BE;
    border-radius: 4px;
}
  
  /* When the checkbox is checked, add a blue background */
  .keep-container input:checked ~ .checkmark {
    background-color: #09D7A6;
    border-color: #09D7A6;
  }

  a.forgot-password {
    color: #7E7E7E;
    font-size: 12px;
    transition: 0.3s;
    font-family: "Poppins", sans-serif;
}
.account-text {
    text-align: center;
    color: #848484;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
}
.account-text a {
    color: #09D7A7;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}
.more{background-color: #fff !important; border: 1px solid #09D7A6 !important; color:#09D7A6 !important;}
.more:hover{background-color: #fff !important; border: 1px solid #09D7A6 !important; color:#09D7A6 !important;}
.login-btn1 {
    background-color: #15C39A;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    border: none;
    width: 200px;
    margin: 40px auto;
    font-family: "Poppins", sans-serif;
    display: block;
    padding: 10px;
    font-weight: 500;
    border-radius: 5px;
    transition: 0.3s;
}
.login-btn1:hover {
    background-color: #000;
}

.login-btn1.view-more-followings:hover{background-color: #09D7A6;color:#fff;}
a.forgot-password:hover {
    color: #09D7A6;
}
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .keep-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .keep-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .keep-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
h1.login-title {
    font-size: 34px;
    font-weight: 600;
    color: #333333;
    line-height: 44px;
    font-family: "Poppins", sans-serif;
}
.login-input {
    position: relative;
}
img.input-img {
    position: absolute;
    left: 25px;
    top: 18px;
}
.reset-forgot-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.custom-input {
    background-color: #09d7a612;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    padding: 15px 15px 15px 60px;
    margin-bottom: 20px;
    line-height: 24px;
    width: 100%;
    position: relative;
    font-family: "Poppins", sans-serif;
    color: #B3B3B3;
    border: 1px solid transparent;
    transition: 0.3s;
}
.contact-right.login-inner button.login-btn1 {
    margin: 0px;
}
textarea.custom-input {
    min-height: 100px;
}
.custom-input:focus {
    border-color: #09D7A6;
    outline: none;
}
.login-inner .desc-text {
    color: #7E7E7E;
    margin: 15px 0px 25px 0px;
    font-size: 14px;
}
a.back-home:hover {
    background-color: #000;
}
.login-inner {
    background-color: #fff;
    padding: 24px;
    box-shadow: 0px 0px 15px 0px #38dfb717;
    border-radius: 20px;
    width: 450px;
    text-align: left;
    z-index: 9;
}
.banner-main-title {
    font-size: 48px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    line-height: 58px;
    margin-bottom: 20px;
}
.banner-img img {
    width: 100%;
}
.view {
    box-shadow: 0px 0px 6px 2px #00000021;
    background-color: #fff;
    position: absolute;
    border-radius: 10px;
    padding: 5px 10px;
}
.banner-img {
    position: relative;
}
.view-count {
    font-size: 26px;
    color: #09D7A6;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 28px;
}
.view.spend {
    left: -9%;
    right: inherit;
    bottom: 17%;
    top: inherit;
    animation: key2 .8s ease infinite alternate;
}
.view {
    box-shadow: 0px 0px 6px 2px #00000021;
    background-color: #fff;
    position: absolute;
    border-radius: 10px;
    padding: 8px 20px;
    text-align: left;
    right: -7%;
    top: 17%;
    animation: key1 .8s ease infinite alternate;
}
@keyframes key1{
    0% {transform: translateX(0px);}
    100% {transform: translateX(-10px);}
  }

  @keyframes key2{
    0% {transform: translateX(-10px);}
    100% {transform: translateX(0px);}
  }
.view-title {
    font-size: 14px;
    color: #BBBBBB;
    line-height: 20px;
    font-family: 'Poppins';
}
.features-left {
    background-color: #09d7a717;
    position: relative;
    padding: 10px 5px;
    border-radius: 15px;
}
.fs1-img {
    position: absolute;
    right: -100px;
    bottom: -80px;
    animation: key1 .8s ease infinite alternate;
}
.features-right.text-left {
    text-align: left;
    padding-left: 40px;
}
.features-left.mr-3 {
    margin-right: 60px;
}
.f1-img {
    width: 100%;
}
.main-title {
    color: #333333;
    font-size: 40px;
    text-align: center;
    font-weight: 600;
    font-family: 'Poppins';
    margin-bottom: 20px;
}
.add-btn {
    border: 2px solid #15C39A;
    border-radius: 5px;
    font-family: 'Poppins';
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    padding: 10px 17px;
    display: inline-block;
    transition: 0.3s;
}
.add-btn:hover {
    background-color: #000;
    color: #fff;
}
.add-btn img {
    margin-right: 8px;
}
.feature-upper span {
    font-size: 34px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    line-height: 44px;
}
.features-right p {
    color: #7E7E7E;
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 26px;
}
.lifetime-price {
    font-size: 70px;
    color: #15C39A;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}
ul.feature-ul {
    padding: 0px;
    margin: 0px;
    text-align: left;
}
ul.feature-ul li span {
    font-family: 'Poppins';
}
ul.feature-ul li i {
    color: #0fcda0;
    font-size: 20px;
    margin-right: 10px;
}
span.monthly-price {
    font-size: 16px;
    color: #7E7E7E;
    font-weight: 400;
    font-family: 'Poppins';
}
.match-div hr {
    background-color: #EEEEEE;
    margin: 25px 0px;
    opacity: 1;
}
.match-div p {
    font-size: 14px;
    color: #7E7E7E;
    line-height: 24px;
    font-family: "Poppins", sans-serif;
}
a.buy-now-btn {
    border-radius: 5px;
    border: 1px solid #15C39A;
    color: #333333;
    display: inline-block;
    padding: 8px 15px;
    margin: 12px 0px;
    transition: 0.3s;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}
.match-div.active a.buy-now-btn {
    background-color: #15c39a;
    color: #fff;
}
section.pricing-sec.review-section {
    background-color: #fff;
    overflow: hidden;
}
.client-photo {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
}
.client-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    color: #fff;
}
.client-review p {
    color: #333333;
    font-size: 16px;
    line-height: 26px;
    font-family: "Poppins", sans-serif;
}
.client-review h6 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 2px;
    font-family: "Poppins", sans-serif;
}
.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 5px;
}
.faq-left {
    padding: 15px 30px 15px 10px;
    font-size: 18px;
    color: #333333;
    border-right: 1px solid #09D7A6;
    font-family: "Poppins", sans-serif;
    width: 300px;
}
footer.custom-footer hr {
    background-color: #fff;
}
.footer-bottom {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: #fff;
    margin-top: 30px;
}
.footer-upper a {
    color: #fff;
    font-family: "Poppins", sans-serif;
    transition: 0.3s;
}
.footer-upper a:hover {
    color: #000;
}
.footer-upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
footer.custom-footer {
    background-color: #15C39A;
    padding: 30px 0px;
}
a.buy-now-btn.read-more {
    margin-top: 25px;
    background-color: #0fcda0;
    color: #fff;
}
a.buy-now-btn.read-more:hover {
    background-color: #000;
    border-color: #000;
}
.faq-right {
    font-family: "Poppins", sans-serif;
    padding: 10px 10px 10px 30px;
    font-size: 14px;
    color: #7E7E7E;
    width: calc(100% - 300px);
}
.faq-div {
    background-color: #fff;
    box-shadow: 0px 0px 25px 0px #f2f2f2;
    padding: 20px;
    display: flex;
    text-align: left;
    border-radius: 20px;
    margin-bottom: 15px;
}
.mySwiper {
    padding-bottom: 30px !important;
    margin-right: -100px !important;
    margin-left: 20px !important;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 63px;
}
span.swiper-pagination-bullet {
    width: 23px;
    height: 8px;
    border-radius: 10px;
    background-color: #0DD0A2;
}
.client-review span {
    font-size: 14px;
    color: #7e7e7e;
    font-family: "Poppins", sans-serif;
}
img.commas-img {
    position: absolute;
    top: -20px;
}
.client-review {
    text-align: left;
    box-shadow: 0px 3px 9px 0px #13c69c24;
    margin: 20px 0px;
    padding: 40px 30px;
    position: relative;
    border-radius: 20px;
}
/* .client-photo::before {
    content: '';
    position: absolute;
    background: linear-gradient(#ffffff00, #060606);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
} */
.client-photo img {
    width: 100%;
}
a.buy-now-btn:hover {
    background-color: #000;
    color: #fff;
    transform: scale(1.1);
}
section.google-chrome-extension::after {
    content: url('../../src/images/banner-arc.png');
    position: absolute;
    left: 0;
    width: 100%;
    right: 0;
    bottom: 10%;
    overflow: hidden;
}
.chrome-extension-a {
    background: linear-gradient(to right, #0A3D4D, #11CB9F);
    display: inline-block;
    padding: 8px 18px;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
}
.client-info p {
    font-family: "Poppins", sans-serif;
    margin: 0px;
}
.client-info h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin: 0px;
}
a.chrome-extension-a img {
    margin-left: 6px;
}
.chrome-left.text-left .main-title {
    text-align: left;
    color: #fff;
    font-size: 54px;
    line-height: 64px;
    margin: 20px 0px;
}
a.get-extension {
    display: inline-block;
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 20px;
    box-shadow: 5px 5px 0px 0px #fff;
    font-size: 16px;
    line-height: 26px;
    font-family: "Poppins", sans-serif;
    transition: 0.3s;
}
.container.chrome-container {
    max-width: 92%;
    margin-right: 0px;
    padding-right: 0;
}
.container.graph-container {
    max-width: 92%;
}
.downloads.wave-block .waves {
    width: 170px;
    height: 170px;
    background: #ffb3004f;
}
.chrome-right img {
    width: 100%;
}
.downloads {
    width: 90px;
    height: 90px;
    background-color: #FFB300;
    text-align: center;
    border-radius: 100%;
    /* display: inline-flex; */
    /* align-items: center; */
    position: absolute;
    left: -20px;
    top: -20px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    padding: 27px 10px;
    font-family: "Poppins", sans-serif;
    line-height: 20px;
    text-align: center;
}
.downloads span {
    font-size: 12px;
    display: block;
    font-weight: normal;
}
.chrome-right {
    background-color: #25DDB2;
    border-radius: 30px 0px 0px 0px;
    padding: 20px 0pc 0px 20px;
    position: relative;
}
a.get-extension:hover {
    background-color: #000;
    border-color: #000;
}
a.get-extension {
    display: inline-block;
    color: #fff;
    border: 1px solid #fff;
    padding: 10px 15px;
    border-radius: 5px;
    margin-top: 20px;
    box-shadow: 5px 5px 0px 0px #fff;
    font-size: 16px;
    line-height: 26px;
    font-family: "Poppins", sans-serif;
    transition: 0.3s;
}
a.get-extension img {
    margin-left: 5px;
    width: 28px;
}
p.desc-text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
}
.row.align-items-flex-end {
    align-items: flex-end;
}
.chrome-left.text-left {
    text-align: left;
    padding: 0px 30px 60px 0px;
}
.google-chrome-extension {
    background: linear-gradient(155deg, rgba(255, 255, 255, 1) 0%, rgba(21, 195, 154, 1) 0%, rgba(9, 215, 166, 1) 100%);
    padding: 90px 0px 0px 0px;
    position: relative;
}
.match-div ul li i {
    margin-top: 2px;
    margin-right: 10px;
    color: #15C39A;
    font-size: 20px;
}
.match-div ul li {
    font-family: "Poppins", sans-serif;
    color: #333333;
    font-size: 14px;
    list-style: none;
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
}
.match-div ul {
    /* margin: 0; */
    padding: 0;
}
.price-title {
    margin-top: 15px;
    font-family: "Poppins", sans-serif;
    font-size: 27px;
    font-weight: bold;
    line-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
}
.match-div.active span.monthly-span {
    background-color: #15c39a;
}
.match-div.active {
    box-shadow: 0px 0px 15px 0px #15c39a36;
}
.match-div {
    background-color: #fff;
    border-radius: 20px;
    padding: 25px 20px 30px 20px;
    text-align: left;
    /* box-shadow: 0px 0px 11px 0px #f2f2f2; */
    margin: 0pc 10px;
}
.monthly-span {
    background-color: #F0B221;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding: 3px 10px;
    display: inline-block;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 500;
}
.pricing-sec {
    padding: 70px 0px;
    background-color: #EFFFFB;
}
.features-right.text-left.feature-r-l {
    padding-left: 0;
    padding-right: 40px;
}
.features-left.mr-3.feature-l-r img.fs1-img {
    right: inherit;
    left: -130px;
    bottom: 0px;
    animation: key2 .8s ease infinite alternate;
}
.features-left.mr-3.feature-l-r {
    margin-right: 0;
    margin-left: 60px;
}
.align-items-center.mt-5.mb-5.center-row.row {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
}
.align-items-center.mt-5.mb-3.center-row.row {
    margin-top: 120px !important;
}
ul.feature-ul li {
    font-size: 14px;
    color: #7E7E7E;
    font-family: 'Poppins';
    margin-top: 14px;
    list-style: none;
    display: flex;
    align-items: flex-start;
}
.feature-upper {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.navbar-nav.nav-inner a.nav-link {
    color: #fff;
    font-family: "Poppins", sans-serif;
    margin-left: 35px;
    font-size: 14px;
}
a.login-btn.nav-link {
    font-weight: 500;
    color: #000 !important;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 5px;
    padding: 8px 20px !important;
    transition: 0.3s;
}
a.login-btn.nav-link:hover {
    background-color: #000;
    color: #fff !important;
    border-color: #000;
}
a.signup-btn.nav-link {
    border: 1px solid #10E9B6;
    margin-left: 10px !important;
    font-weight: 500;
    border-radius: 5px;
    padding: 8px 20px !important;
    transition: 0.3s;
    background: linear-gradient(155deg, rgba(255, 255, 255, 1) 0%, rgba(21, 195, 154, 1) 0%, rgba(9, 215, 166, 1) 100%);
}
a.signup-btn.nav-link:hover {
    background: #000 !important;
    color: #fff !important;
    border-color: #000;
}
.banner-section {
    padding: 40px 0px;
    background-image: url(../images/banner-arc.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    position: relative;
}

.video-main {
    position: absolute;
    display: inline-block;
    left: 13%;
    top: 23%;
}

.video-main.video-main1 {
    left: inherit;
    top: 18%;
    right: 10%;
}
  
  .video {
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 100%;
    background: transparent;
    color: #fff;
    display: inline-block;
    background: #fff;
    z-index: 999;
  }
/* Dev custom */


.main-header-logo {
    height: 65px;
}
img.dashbaord-logo {
    height: 65px;
}
/* Card */
.card {
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  }
  
  .card-header,
  .card-footer {
    border-color: #ebeef4;
    background-color: #fff;
    color: #798eb3;
    padding: 15px;
  }
  
  .card-title {
    padding: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: #012970;
    font-family: "Poppins", sans-serif;
  }
  
  .card-title span {
    color: #899bbd;
    font-size: 14px;
    font-weight: 400;
  }
  
  .card-body {
    padding: 0 20px 20px 20px;
  }
  
  .card-img-overlay {
    background-color: rgba(255, 255, 255, 0.6);
  }
  

  .rmsc.multi-select {
    width: 100%;
}

.d-media-active i {
    margin-left: 2px;
}
.sponsered-div img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}
.d-media-left p i {
    border: 1px solid #333;
    border-radius: 100%;
    height: 14px;
    line-height: 13px;
    text-align: center;
    width: 14px;
}
.download-media p {
    font-size: 11px;
    text-align: left;
    min-height: 48px;
}
.react-multi-carousel-item {
    
    padding: 0px 5px !important;
}
.login-btn1.see-ads:hover {
    color: #fff;
    text-decoration: none;
}

  
  @keyframes waves {
    0% {
      -webkit-transform: scale(0.2, 0.2);
      transform: scale(0.2, 0.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
      opacity: 0.9;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
      -webkit-transform: scale(0.9, 0.9);
      transform: scale(0.9, 0.9);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
  }
  
  .fa-play:before {
    content: "\f04b";
  }
  
  .waves {
    position: absolute;
    width: 150px;
    height: 150px;
    background: rgba(256, 256, 256, 0.3);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border-radius: 100%;
    right: -40px;
    bottom: -40px;
    /* z-index: -1; */
    animation: waves 3s ease-in-out infinite;
}
          
  .wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  
  .wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }

  span.circle1 {
    position: absolute;
    width: 234px;
    height: 234px;
    background-color: #09d7a757;
    left: 10px;
    top: 30px;
    border-radius: 100%;
}

span.circle2 {
    position: absolute;
    width: 154px;
    height: 154px;
    background-color: #15c49b4d;
    border-radius: 100%;
    right: 10px;
    bottom: 154px;
}
.feature-upper img {
    box-shadow: 0px 9px 10px 0px #15c39a17;
    border-radius: 100%;
    margin-right: 15px;
}

@media screen and (max-width: 1300px) {
.container.chrome-container {
    max-width: 97%;
}
input.custom-input {
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.login-btn1 {
    margin: 25px auto;
}
div#navbarScroll {
    box-shadow: none !important;
}
.price-title {
    font-size: 30px;
    line-height: 40px;
}
/********extension-bar*******/
.extension-left.center {
    padding-left: 20px;
}
/**************/
}

@media screen and (max-width: 1200px) {
section.banner-section.pricing-banner {
    min-height: 230px;
    padding-top: 30px;
}
.extension-bar.dashbaord-extension {
    width: 100%;
}
.profile-div .login-inner {
    padding: 45px 40px 45px 90px;
}
}

@media screen and (max-width: 991px) {
.contact-right.login-inner {
    padding: 35px;
}
.profile-div {
    width: 95%;
    margin: 10px auto;
}
main.container-fluid.main-container .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
}
.contact-left {
    height: 380px;
}
.about-left {
    text-align: left;
    width: 48%;
}
.main-nav a.navbar-brand {
    padding: 0px;
}
/***********extension-bar***********/
.extension-bar {
    display: block;
    padding: 8px;
    width: 88%;
}
.extension-left {
    padding: 10px 0px 10px 0px !important;
    border-bottom: 1px solid #09d7a657;
    border-right: none;
    width: 100%;
    text-align: left;
}
.extension-left.right {
    border-right: none;
    padding-left: 0px;
    border-bottom: none;
    padding-bottom: 10px;
    padding-right: 0px;
}
/****************************/
button.navbar-toggler {
    background-color: #fff;
}
.col-md-9.col-lg-10.ml-md-auto.px-0.custom-nine-col {
    max-width: calc(100% - 0px) !important;
    width: calc(100% - 0px) !important;
    flex: 0 0 100%;
    margin-top: 51px;
    padding: 20px 5px !important;
}
img.s-img {
    height: 240px;
}
button#open-sidebar span::before {
    color: #0aaf88;
}
button#open-sidebar:focus {
    box-shadow: none;
}
section.plan-divvv.pt-5.pb-5 {
    padding: 0px 0px !important;
}
button#open-sidebar {
    padding-left: 0px;
}
.dashbaord-title {
    display: none;
}
img.dashbaord-logo {
    width: 120px;
}
.col-md-8.offset-md-2.col-sm-12 {
    margin-left: 0;
    width: 100%;
    flex: 0 0 100% !important;
    max-width: 100%;
}
.navbar-nav.nav-inner a.nav-link {
    margin-left: 0px;
    margin-bottom: 10px;
}
a.signup-btn.nav-link {
    margin-left: 0px !important;
}
.banner-main-title {
    font-size: 35px;
    line-height: 45px;
}
.view-count {
    font-size: 20px;
    line-height: 24px;
}
.banner-section {
    padding: 30px 0px;
}
section.our-top-features.pt-5.pb-5 {
    padding: 30px 0px !important;
}
.main-title {
    font-size: 35px;
    line-height: 45px;
}
.feature-upper span {
    font-size: 28px;
    line-height: 38px;
}
.align-items-center.mt-5.mb-3.center-row.row {
    margin-top: 60px !important;
}
.align-items-center.mt-5.mb-5.center-row.row {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}
.pricing-sec {
    padding: 30px 0px;
}
.match-div {
    margin: 0pc 0px;
}
section.pricing-sec .col-md-8.offset-md-2.col-sm-12 {
    margin: 0px;
    width: 100%;
}
.google-chrome-extension {
    padding: 30px 0px 0px 0px;
}
.chrome-extension-a {
    padding: 8px 15px;
    font-size: 16px;
}
.chrome-left.text-left .main-title {
    font-size: 35px;
    line-height: 45px;
    margin: 15px 0px;
}
.client-info {
    padding: 8px;
}
footer.custom-footer {
    padding: 15px 0px;
}
.footer-bottom {
    margin-top: 15px;
}
.features-left.mr-3.learn-left {
    margin-right: 0px;
}
.extension-bar.dashbaord-extension .extension-left {
    padding: 10px 15px !important;
    width: 100% !important;
}
}


@media screen and (max-width: 767px) {
.about-us-divv {
    display: block;
    padding: 0px 10px;
}
ul.card-ul.cvc-ul {
    top: inherit;
}
ul.card-ul {
    position: inherit;
    left: inherit;
    top: inherit;
    margin-bottom: 12px;
    margin-left: 15px;
}
.login-inner.affilate-link-div {
    padding-top: 20px !important;
}
h1.login-title {
    font-size: 30px;
    line-height: 40px;
}
.profile-inner.edit-profile-div.change-password {
    padding-right: 0%;
}
.profile-div {
    align-items: flex-start;
}
ul.profile-ul {
    margin-top: 30px;
}
.profile-img {
    width: 118px;
    height: 118px;
    margin-right: 0px;
    margin-bottom: 20px;
}
.profile-inner {
    display: block;
    margin-top: 20px;
}
ul.profile-ul li a {
    padding: 10px;
    width: 58px;
}
.profile-inner {
    margin-bottom: 0px;
}
ul.profile-ul li a img {
    width: 22px;
}
ul.profile-ul li.active a {
    padding: 15px;
    width: 70px;
}
.profile-div .login-inner {
    padding: 25px 25px 25px 70px;
}
nav.w-100.d-flex.px-4.py-2.mb-4.shadow-sm {
    padding: 8px 5px !important;
}
main.container-fluid.main-container .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
}
a.logout {
    padding: 10px 18px;
    position: inherit;
}
a.logout img {
    width: 30px;
}
.sidebar-left a {
    padding: 10px 18px;
}
.sidebar {
    max-width: 18rem;
    transform: translateX(-100%);
    transition: transform 0.4s ease-out;
    z-index: 9;
    top: 52px;
}
  
  .sidebar.active{
    transform : translateX(0);
  }
.contact-us-inner {
    display: block;
}
.contact-right.login-inner button.login-btn1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 0px;
}
.contact-right.login-inner {
    padding: 25px;
    width: 100%;
    margin-top: 15px;
    text-align: center;
}
.contact-left {
    height: 180px;
    width: 100%;
    border-radius: 20px;
}
.learn-left {
    margin: 0px !important;
}
section.pricing-sec.review-section.pt-0.mt-5.faq-main-outer {
    margin-bottom: 40px;
}
.learn-main-outer .features-right.text-left {
    margin-top: 40px !important;
}
.container.mt-5 {
    margin-top: 0px !important;
}
.step1 {
    padding: 30px;
    margin-bottom: 15px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}
.step1 img {
    width: auto;
}
a.video.video-popup.mfp-iframe, span.circle2, span.circle1, section.banner-section .waves-block {
    display: none;
}
.about-left {
    width: 100%;
}
.custom-modal .modal-body {
    padding: 20px;
}
.affilate-link {
    font-size: 26px;
}
.custom-modal button.close {
    width: 40px;
    height: 40px;
    font-size: 30px;
    line-height: 30px;
    right: -5px;
}
img.commission {
    right: -10px;
    top: -20px;
    width: 80px;
}
.lifetime-plan::after {
    top: 47px;
    font-size: 18px;
}
.lifetime-price {
    font-size: 60px;
}
.about-left.ambition-left {
    margin-top: 25px;
}
.align-items-center.mt-5.mb-5.section-space.row {
    margin: 0px !important;
}
.feature-upper.justify-content-center.mb-5 {
    margin-bottom: 15px !important;
}
.worldwide {
    margin-bottom: 15px;
}
.about-left .main-title {
    font-size: 30px;
    margin-bottom: 5px !important;
}
section.banner-section.pricing-banner {
    min-height: auto;
    padding-top: 20px;
    background-position: bottom 0px center;
    padding-bottom: 10px;
}
.banner-img {
    margin: 0px 25px;
}
.view {
    padding: 4px 10px;
}
.view-count {
    font-size: 14px;
    line-height: 20px;
}
.match-div {
    margin: 0pc 0px 20px 0px;
}
.container.chrome-container {
    max-width: 100%;
    padding: 0px 20px;
}
.downloads {
    left: 0px;
}
.view-title {
    font-size: 12px;
    line-height: 16px;
}
.fs1-img {
    right: -100px;
    bottom: -170px;
}
.main-title {
    margin-bottom: 20px !important;
}
.mySwiper {
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 20px;
}
.faq-div {
    display: block;
    padding: 10px;
}
.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -5px;
}
a.buy-now-btn.read-more {
    margin-top: 15px;
    margin-bottom: 30px;
}
.faq-left {
    border-right: none;
    border-bottom: 1px solid #09D7A6;
    padding: 10px !important;
    width: 100%;
}
section.pricing-sec.review-section.pt-0 {
    padding: 0px 10px;
}
.footer-upper {
    display: block;
    text-align: center;
}
.me-auto.my-2.my-lg-0.nav-inner.nav {
    justify-content: center;
}
.faq-right {
    width: 100%;
    padding: 10px !important;
}
.features-right.text-left {
    padding-left: 0px;
    margin-top: 160px;
}
.features-right.text-left.feature-r-l {
    padding-right: 0px;
    margin-top: 130px;
}
.align-items-center.mt-5.mb-5.center-row.row, .align-items-center.mt-5.mb-3.center-row.row {
    flex-direction: column-reverse;
}
.features-left.mr-3.feature-l-r img.fs1-img {
    left: -80px;
    bottom: -140px;
}
}





.login-inner.affilate-link-div .card {
    box-shadow: none;
    border-radius: 10px;
    background-color: #09d7a612;
}
a.logout {
    background-color: #fff;
}
.login-inner.affilate-link-div .react-tabs__tab-list {
    border-bottom: 1px solid #09d7a612;
}
.login-inner.affilate-link-div .react-tabs__tab-list li {
    color: #09d7a6;
    font-weight: 500;
}
.login-inner.affilate-link-div .card-body h5 span {
    color: #09d7a6;
}
.login-inner.affilate-link-div .react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected {
    background-color: #09d7a6;
    color: #fff;
    border: 1px solid #09d7a6;
}
.login-inner.affilate-link-div .react-tabs__tab-panel {
    padding: 20px 0px;
}
.login-inner.affilate-link-div .react-tabs__tab-panel h1.login-title {
    font-size: 24px;
    line-height: 34px;
}
.login-inner.affilate-link-div .react-tabs__tab-panel h2 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    border: none;
}
.login-inner.affilate-link-div .react-tabs__tab-panel table thead th {
    border: none;
    font-size: 14px;
    font-weight: 600;
}
.login-inner.affilate-link-div .react-tabs__tab-panel table tbody td {
    font-size: 14px;
}
.login-inner.affilate-link-div .react-tabs__tab-panel table thead tr {
    background-color: #eefcf9;
}
.login-inner.affilate-link-div .react-tabs__tab-panel table tbody p {
    width: 100%;
    text-align: center;
    display: block;
    margin: 0;
    padding: 10px;
    font-size: 14px;
}

.lifetime-dashboard-plan ul li{text-align: left;}